import React, { useState, useEffect, useCallback } from 'react';
import ProductCard from './ProductCard';
const ProductGrid = ({ fetchMoreProducts, products, quantities, handleAddToCart, handleQuantityChange, handleProductClick, selectedPackingSizes, handlePackingSizeChange }) => {
  const [isFetching, setIsFetching] = useState(false);

  // Infinite scrolling effect
  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) {
      return;
    }
    setIsFetching(true);
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!isFetching) return;
    fetchMoreProducts();  // Trigger the parent to fetch more products
    setIsFetching(false);
  }, [isFetching, fetchMoreProducts]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    {products.map((product) => (
      <ProductCard
        key={product.id}
        product={product}
        quantities={quantities}
        handleAddToCart={handleAddToCart}
        handleQuantityChange={handleQuantityChange}
        handlePackingSizeChange={handlePackingSizeChange}
        selectedPackingSizes={selectedPackingSizes}
        onProductClick={handleProductClick}  // Pass the click handler
      />
    ))}
  </div>

  );
};

export default ProductGrid;
