// OrderSummaryPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';

function OrderSummaryPage() {
  const [order, setOrder] = useState(null);
  const { authToken } = useAuth();

  useEffect(() => {
    // Fetch the latest order
    const fetchOrder = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/orders/latest/`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        setOrder(response.data);
      } catch (error) {
        console.error('Error fetching order summary:', error);
      }
    };
    fetchOrder();
  }, [authToken]);

  if (!order) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-xl text-gray-700">Loading order summary...</p>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg my-10">
      <h1 className="text-3xl font-bold mb-6 text-center text-green-600">Order Placed Successfully!</h1>
      <p className="text-lg text-gray-700 mb-4">
        Thank you for your purchase. Your order ID is <span className="font-semibold">#{order.id}</span>.
      </p>
      <h2 className="text-2xl font-bold text-gray-800 mb-4">Order Details</h2>
      <div className="bg-gray-50 p-4 rounded-lg">
        {order.order_items.map((item, index) => (
          <div key={index} className="flex justify-between items-center mb-4">
            <div>
              <p className="font-semibold text-gray-800">{item.product.name}</p>
              <p className="text-sm text-gray-600">
                Quantity: {item.quantity} x ₹{item.price}
              </p>
            </div>
            <p className="font-semibold text-gray-800">
              ₹{(item.price * item.quantity).toFixed(2)}
            </p>
          </div>
        ))}
        <div className="border-t pt-4">
          <div className="flex justify-between text-gray-700">
            <p>Total</p>
            <p>₹{order.total}</p>
          </div>
        </div>
      </div>
      {/* Shipping Address */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Shipping Address</h2>
        <p className="text-gray-700">
          {order.shipping_address.name}, {order.shipping_address.street}, {order.shipping_address.city},{' '}
          {order.shipping_address.state}, {order.shipping_address.postal_code},{' '}
          {order.shipping_address.country} - {order.shipping_address.phone_number}
        </p>
      </div>
    </div>
  );
}

export default OrderSummaryPage;
