import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ShoppingCartIcon } from '@heroicons/react/24/solid';
import { addToCart, getCartItemCount } from './cartUtils'; // Import cart utility functions

// Import ProductGrid component
import ProductGrid from './common/ProductGrid';

// Helper Components
const SectionTitle = ({ title }) => (
  <h2 className="text-3xl font-bold mb-8 text-gray-800 text-center">{title}</h2>
);

const CategoryCard = ({ category }) => (
  <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 text-center p-6 flex flex-col items-center">
    <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mb-4">
      <span className="text-2xl font-bold text-blue-500">
        {category.name.charAt(0)}
      </span>
    </div>
    <h3 className="text-xl font-semibold text-gray-800">{category.name}</h3>
    <p className="text-sm text-gray-600 mt-2 line-clamp-3">{category.description}</p>
    <Link
      to={`/category/${category.id}/products`}
      className="mt-auto bg-blue-500 text-white rounded-md mt-4 px-6 py-2 hover:bg-blue-600"
    >
      Explore
    </Link>
  </div>
);

const BrandCard = ({ brand }) => (
  <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 text-center p-6 flex flex-col items-center">
    {brand.logo ? (
      <img
        src={brand.logo}
        alt={brand.name}
        className="w-24 h-24 object-contain mb-4"
      />
    ) : (
      <div className="w-24 h-24 bg-blue-100 rounded-full flex items-center justify-center mb-4">
        <span className="text-3xl font-bold text-blue-500">
          {brand.name.charAt(0)}
        </span>
      </div>
    )}
    <h3 className="text-xl font-semibold text-gray-800">{brand.name}</h3>
    <Link
      to={`/brand/${brand.id}/products`}
      className="mt-auto bg-blue-500 text-white rounded-md mt-4 px-6 py-2 hover:bg-blue-600"
    >
      Explore
    </Link>
  </div>
);

const EcomPage = () => {
  const [recommendedProducts, setRecommendedProducts] = useState([]);
  const [bestSellingProducts, setBestSellingProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [selectedPackingSizes, setSelectedPackingSizes] = useState({});
  const [cartItemCount, setCartItemCount] = useState(0); // Added cartItemCount state
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Handle adding a product to the cart
  const handleAddToCart = (product) => {
    const selectedPackingSize = product.packing_sizes.find(
      (packingSize) => packingSize.id === selectedPackingSizes[product.id]
    );
    const quantity = quantities[product.id] || 1;
    addToCart(product, selectedPackingSize, quantity);

    setCartItemCount(getCartItemCount()); // Update cart item count
  };

  // Handle quantity changes
  const handleQuantityChange = (productId, change) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: Math.max(1, (prevQuantities[productId] || 1) + change),
    }));
  };

  // Handle packing size changes
  const handlePackingSizeChange = (e, productId, packingSizeId) => {
    e.stopPropagation();
    setSelectedPackingSizes((prevSelected) => ({
      ...prevSelected,
      [productId]: packingSizeId,
    }));
  };

  // Navigate to the product details page
  const handleProductClick = (productId) => {
    navigate(`/products/${productId}`);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem('authToken');
      const config = token
        ? { headers: { Authorization: `Token ${token}` } }
        : {};
      setLoading(true);

      try {
        // Fetch best-selling products, categories, and brands
        const [bestSellingRes, categoryRes, brandRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_API_URL}/products/bestsellers/`),
          axios.get(`${process.env.REACT_APP_API_URL}/products/categories/`),
          axios.get(`${process.env.REACT_APP_API_URL}/products/brands/`),
        ]);

        setBestSellingProducts(bestSellingRes.data.results || []);
        setCategories(categoryRes.data || []);
        setBrands(brandRes.data || []);

        // Initialize selected packing sizes
        const initialPackingSizes = {};
        const initialQuantities = {};

        bestSellingRes.data.results.forEach((product) => {
          if (product.packing_sizes && product.packing_sizes.length > 0) {
            initialPackingSizes[product.id] = product.packing_sizes[0].id;
          }
        });
        setSelectedPackingSizes(initialPackingSizes);
        setQuantities(initialQuantities);

      } catch (error) {
        console.error('Failed to load data:', error);
      }

      // Fetch recommended products if user is authenticated
      if (token) {
        try {
          const recommendedRes = await axios.get(
            `${process.env.REACT_APP_API_URL}/products/recommended/`,
            config
          );
          setRecommendedProducts(recommendedRes.data.results || []);

          // Update selected packing sizes
          setSelectedPackingSizes((prevSelected) => {
            const newSelected = { ...prevSelected };
            if (recommendedRes.data && recommendedRes.data.results) {
              recommendedRes.data.results.forEach((product) => {
                if (product.packing_sizes && product.packing_sizes.length > 0) {
                  newSelected[product.id] = product.packing_sizes[0].id;
                }
              });
            }
            return newSelected;
          });
        } catch (error) {
          console.error('Failed to load recommended products:', error);
        }
      }

      setLoading(false);
    };
    fetchProducts();
  }, []);

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-gray-500 text-lg">Loading...</div>
      </div>
    );

  return (
    <section className="bg-gray-50 min-h-screen">
      {/* Hero Section */}
      <div
        className="bg-cover bg-center h-72 flex items-center justify-center relative"
        style={{
          backgroundImage:
            "url('https://source.unsplash.com/1600x900/?agriculture,farm')",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-30"></div>
        <h1 className="text-4xl font-bold text-white shadow-lg relative">
          Welcome to Krshak
        </h1>
      </div>

            {/* Cart Icon with Count */}
            <div className="fixed top-4 right-4">
        <Link to="/cart">
          <div className="relative">
            <ShoppingCartIcon className="h-8 w-8 text-gray-700" />
            {cartItemCount > 0 && (
              <span className="absolute -top-2 -right-2 bg-red-500 text-white text-xs rounded-full px-2 py-1">
                {cartItemCount}
              </span>
            )}
          </div>
        </Link>
      </div>

      <div className="container mx-auto px-6 py-12">
        {/* Recommended Products */}
        {recommendedProducts.length > 0 && (
          <>
            <SectionTitle title="Recommended Products" />
            <ProductGrid
              fetchMoreProducts={() => {}}
              products={recommendedProducts}
              quantities={quantities}
              handleAddToCart={handleAddToCart}
              handleQuantityChange={handleQuantityChange}
              handleProductClick={handleProductClick}
              selectedPackingSizes={selectedPackingSizes}
              handlePackingSizeChange={handlePackingSizeChange}
            />
          </>
        )}

        {/* Best Selling Products */}
        <SectionTitle title="Best Sellers" />
        {bestSellingProducts.length > 0 ? (
          <ProductGrid
            fetchMoreProducts={() => {}}
            products={bestSellingProducts}
            quantities={quantities}
            handleAddToCart={handleAddToCart}
            handleQuantityChange={handleQuantityChange}
            handleProductClick={handleProductClick}
            selectedPackingSizes={selectedPackingSizes}
            handlePackingSizeChange={handlePackingSizeChange}
          />
        ) : (
          <p className="text-center text-gray-600">
            No best-selling products available.
          </p>
        )}

        {/* Categories */}
        <SectionTitle title="Categories" />
        {categories.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {categories.map((category) => (
              <CategoryCard key={category.id} category={category} />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600">No categories available.</p>
        )}

        {/* Brands */}
        <SectionTitle title="Brands" />
        {brands.length > 0 ? (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8">
            {brands.map((brand) => (
              <BrandCard key={brand.id} brand={brand} />
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-600">No brands available.</p>
        )}
      </div>
    </section>
  );
};

export default EcomPage;
